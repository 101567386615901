import PropTypes from 'prop-types';
import Head from 'next/head';
import Link from 'next/link';
import { Form, message, Select } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import MetaSeo from './MetaSeo';
import axios from 'axios';
import { isIOS } from 'react-device-detect';
import { getAllServices } from '../redux/actions/services';
import { getDisplayName } from 'next/dist/shared/lib/utils';
import useTrans from '../hooks/useTrans';
import Header from './Header';
function Layout({ children, webViewMobile, web }) {
  const [infor, setInfor] = useState();
  const trans = useTrans();
  const dispatch = useDispatch();
  const [service, setService] = useState([]);
  const [show, setShow] = useState(false);
  const [none, setNone] = useState(false);
  const [marginTop, setMarginTop] = useState(0);
  const [show_menu_mobile, setShowMenuMobile] = useState();

  const [show_header, setShowHeader] = useState('sticky');
  const [padding_top, setPaddingTop] = useState('');
  const router = useRouter();
  const [y, setY] = useState(0);
  let positionY = React.useRef(null);
  const refClickLanguage = useRef();
  useEffect(() => {
    localStorage.setItem('lg', router.locale);
    getListServiceAll();
    getInfor(router.locale);
    window.addEventListener('scroll', onScroll);
  }, [router.locale]);

  const getInfor = async locale => {
    await axios
      .get(
        `${process.env.NEXT_PUBLIC_API_SERVER_URL}/app/home/get-list-home-information`,
        {
          headers: {
            'Content-Type': 'application/json',
            locale: locale,
          },
        },
      )
      .then(res => {
        // console.log('');
        const data = res.data;
        setInfor(data);
      })
      .catch(error => console.log(error));
  };
  const getListServiceAll = async () => {
    const data = await getAllServices({}, dispatch);
    if (data) {
      setService(data?.rows);
    }
  };

  useEffect(() => {
    let head = document.getElementsByTagName('head')[0];
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    // console.log(router);
    if (router.locale === 'en' || router.locale === 'jp') {
      let lisLink = head.querySelector(
        `#${router.locale === 'en' ? 'jp' : 'en'}`,
      );
      if (lisLink) {
        head.removeChild(lisLink);
      }
    }
    if (router.locale === 'vi') {
      let lisLink = head.querySelectorAll(`.newLanguage`);
      if (lisLink.length) {
        head.removeChild(lisLink[0]);
      }
    }
    if (router.locale === 'en') {
      console.log('check EN');
      link.id = 'en';
      link.className = 'newLanguage';
      link.href = '/assets/css/styleEN.css';
    }

    if (router.locale === 'jp') {
      console.log('check JP');
      link.id = 'jp';
      link.className = 'newLanguage';
      link.href = '/assets/css/styleJP.css';
    }
    head.appendChild(link);

    return () => {
      console.log(head);
    };
  }, [router?.locale]);

  // useEffect(() => {
  //   (() => {
  //     if (show) {
  //       lockScroll();
  //     } else {
  //       unlockScroll();
  //     }
  //   })();
  // }, [show]);
  // const lang = () => {
  //   setNone(!none);
  //   console.log('hiiii');
  // };

  // const displayDrop = () => {
  //   setShow(!show);
  // };
  // const lockScroll = React.useCallback(() => {
  //   positionY.current = window.scrollY;
  //   document.body.style.overflowY = 'hidden';
  //   document.body.style.position = 'fixed';
  //   document.body.style.top = `${positionY.current}px`;
  // }, []);

  // const unlockScroll = React.useCallback(() => {
  //   document.body.style.overflowY = '';
  //   document.body.style.position = '';
  //   document.body.style.top = '';
  //   scrollTo(0, positionY.current);
  // }, []);
  // useEffect(() => {
  //   (() => {
  //     if (show) {
  //       lockScroll();
  //     } else {
  //       unlockScroll();
  //     }
  //   })();
  // }, [show]);
  // const showMenuApp = (goTop = false) => {
  //   if (goTop) {
  //     document.body.style.overflowX = 'auto';
  //     window.scrollTo(0, 0);
  //     document.body.style.overflowX = 'hidden';
  //   }
  //   if (show_menu_mobile === 'open') {
  //     // unlockScroll();
  //     setShowMenuMobile('');
  //   } else {
  //     setShowMenuMobile('open');
  //     // lockScroll();
  //   }
  // };
  // const setLanguageSelect = key => {
  //   localStorage.setItem('lg', key);
  //   setValueSelect(key);
  //   setShow('');
  //   setNone('');
  //   const { pathname, asPath, query } = router;
  //   router.push({ pathname, query }, asPath, { locale: key });
  // };

  const onScroll = e => {
    // let window = e.currentTarget;
    if (!window.vinhY) {
      window.vinhY = 0;
    }
    if (window.pageYOffset > 10) {
      if (window.vinhY > window.pageYOffset) {
        setShowHeader('sticky bgrColor');
      } else if (window.vinhY < window.pageYOffset) {
        setShowHeader('hiddenn');
      }
    } else {
      setShowHeader('sticky');
    }

    window.vinhY = window.pageYOffset;
  };

  let arr = router.components || [];
  let result = arr
    ? Object.keys(router?.components || []).includes('/404')
    : false;

  return (
    <>
      {/* <MetaSeo {...dataSeo} /> */}
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>Eyefire</title>
        <link rel="shortcut icon" href="/assets/img/logo/favicon.png"></link>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <div id={web}>
        <div id={`header`} className={`${show_header}`}>
          <Header />
        </div>
        <main>{children}</main>
        {/* footer */}
        <div id="footer">
          <footer
            className="l-footer"
            style={{
              backgroundColor:
                router.pathname === '/news/[sort_url]' && !result
                  ? '#fff'
                  : '#001530',
            }}
          >
            <div className="l-footer__contact">
              <div className="l-container">
                {web !== 'contact' ? (
                  <div className="l-footer__contact__inner">
                    <h3>{trans.layout.contact_footer}</h3>
                    <p style={{ padding: '5px', marginBottom: '51px' }}>
                      {trans.layout.content_contact_footer1}
                      <br />
                      {trans.layout.content_contact_footer2}
                    </p>
                    <div className="l-footer__contact__group-btn">
                      <Link href="/contact">
                        <a href="#" className="c-btn">
                          <img
                            src="/assets/img/common/icn_mail.svg"
                            alt=""
                            style={{ marginRight: '5px' }}
                          />
                          {trans.layout.button_contact}
                        </a>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="l-footer__content">
              <div className="l-footer__main">
                <div className="l-container-fluid">
                  <div className="l-footer__main__header">
                    <div className="l-footer__main__header__logo">
                      <Link href="/">
                        <a href="#" className="l-footer__main__logo">
                          <img src="/assets/img/common/icn_footer.svg" alt="" />
                        </a>
                      </Link>
                    </div>
                    <div className="l-footer__main__header__slogan">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trans.layout.slogan,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div>

                  <div className="l-row ">
                    <div className="l-col-auto footer-col-menu l-footer__main__office">
                      <h3 className="l-footer__main__title">
                        {trans.layout.office}
                      </h3>
                      <div
                        className="l-footer__main__content"
                        style={{ width: '100%' }}
                      >
                        <div className="l-footer__main__content__item">
                          <h5>{trans.layout.adrHn}</h5>
                          <p>
                            <div
                              className="l-footer__main__content__desAdr"
                              dangerouslySetInnerHTML={{
                                __html: trans.layout.address1,
                              }}
                            ></div>
                            <div className="mapView">
                              <a
                                className="link-btn"
                                target="_blank"
                                href="https://www.google.com/maps/place/Ng.+19+%C4%90.+Nguy%E1%BB%85n+Tr%C3%A3i,+Kh%C6%B0%C6%A1ng+Trung,+%C4%90%E1%BB%91ng+%C4%90a,+H%C3%A0+N%E1%BB%99i/@21.0022651,105.8203236,17z/data=!3m1!4b1!4m5!3m4!1s0x3135ac84f6869bf1:0xd2a5ff274402a0a0!8m2!3d21.0022651!4d105.8203236?hl=en"
                              >
                                Map view
                              </a>
                              <img src="/assets/img/home/arrowRight.svg" />
                            </div>
                          </p>
                        </div>
                        <div className="l-footer__main__content__item">
                          <h5>{trans.layout.adrHcm}</h5>
                          <p>
                            <div
                              className="l-footer__main__content__desAdr"
                              dangerouslySetInnerHTML={{
                                __html: trans.layout.address2,
                              }}
                            ></div>
                            <div className="mapView">
                              <a
                                className="link-btn"
                                target="_blank"
                                href="https://www.google.com/maps/place/17+H%E1%BB%93+V%C4%83n+Hu%C3%AA,+Ph%C6%B0%E1%BB%9Dng+9,+Ph%C3%BA+Nhu%E1%BA%ADn,+H%E1%BB%93+Ch%C3%AD+Minh+70022,+Vi%E1%BB%87t+Nam/@10.8013743,106.6729851,17z/data=!3m1!4b1!4m6!3m5!1s0x3175292807e7e625:0x1f789038895ceb8d!8m2!3d10.801369!4d106.67556!16s%2Fg%2F11hjkqgthr?hl=vi-VN&entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                              >
                                Map view
                              </a>
                              <img src="/assets/img/home/arrowRight.svg" />
                            </div>
                          </p>
                        </div>
                        <div className="l-footer__main__content__item">
                          <h5>{trans.layout.adrJp}</h5>
                          <p>
                            <div
                              className="l-footer__main__content__desAdr"
                              dangerouslySetInnerHTML={{
                                __html: trans.layout.address3,
                              }}
                            ></div>
                            <div className="mapView">
                              <a
                                className="link-btn"
                                target="_blank"
                                href="https://www.google.co.jp/maps/place/THE+HUB+%E7%AB%8B%E5%B7%9D+nex+Inc./@35.6959613,139.4119304,17z/data=!3m2!4b1!5s0x6018e17b0d34626d:0x689d36f4af787ff0!4m5!3m4!1s0x6018e174c9931d8b:0x55275e3f4e298915!8m2!3d35.6959613!4d139.4119304"
                              >
                                Map view
                              </a>
                              <img src="/assets/img/home/arrowRight.svg" />
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="l-col-auto footer-col-menu l-footer__main__solution">
                      <h3 className="l-footer__main__title uppercase">
                        {trans.layout.solution}
                      </h3>
                      <div className="l-footer__main__content">
                        <ul>
                          {infor?.data?.solution?.rows &&
                            infor?.data?.solution?.rows?.map((item, index) => {
                              return (
                                <li>
                                  <Link href="/solution">
                                    <a>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item?.title_footer,
                                        }}
                                      ></div>
                                      {/* {item?.title_footer} */}
                                    </a>
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="l-col-auto footer-col-menu l-footer__main__technology">
                      <h3 className="l-footer__main__title uppercase">
                        {trans.layout.technology}
                      </h3>
                      <div className="l-footer__main__content">
                        <ul>
                          {infor?.data?.feature_tech?.rows &&
                            infor?.data?.feature_tech?.rows.map(
                              (item, index) => {
                                return (
                                  <li className="li_tech">
                                    <Link href="/technology">{item.title}</Link>
                                  </li>
                                );
                              },
                            )}
                        </ul>
                        {/* <div className="l-footer__main__content-rules item-pc">
                          <a href="#" className="l-footer__main__link">
                            {trans.layout.rule1}
                          </a>
                          <a href="#" className="l-footer__main__link">
                            {trans.layout.rule2}
                          </a>
                        </div> */}
                      </div>
                    </div>
                    <div className="l-col-auto footer-col-menu l-footer__main__menu">
                      <h3 className="l-footer__main__title uppercase">
                        {trans.layout.menu}
                      </h3>
                      <div className="l-footer__main__content">
                        <ul>
                          <li>
                            <Link href="/">
                              <a>{trans.layout.home}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/technology">
                              <a>{trans.layout.technology}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/solution">
                              <a>{trans.layout.solution}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/product">
                              <a>{trans.layout.product}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/sdk">
                              <a>{trans.layout.sdk}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/news">
                              <a>{trans.layout.news}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/contact">
                              <a>{trans.layout.contact}</a>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="l-footer__main__content__keep item-sp">
                      <h3 className="l-footer__main__title">
                        {trans.layout.keep}
                      </h3>
                      <div className="l-footer__main__content__keep-item">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/EyeFireCorp"
                              target={'_blank'}
                            >
                              <img
                                src="/assets/img/home/img_icn_facebook.svg"
                                alt=""
                              />
                              {/* <svg
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16.5"
                                  cy="16.5"
                                  r="16.5"
                                  fill="white"
                                />
                                <path
                                  d="M20.1116 10.2951H21.9233V7.13974C21.6107 7.09674 20.5358 7 19.2839 7C16.6718 7 14.8825 8.643 14.8825 11.6627V14.4419H12V17.9693H14.8825L14.8825 26.845H18.4165V17.9701H21.1824L21.6215 14.4427H18.4157V12.0125C18.4165 10.993 18.6911 10.2951 20.1116 10.2951Z"
                                  fill="#000E33"
                                />
                              </svg> */}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/tcom-corporation"
                              target={'_blank'}
                            >
                              <img
                                src="/assets/img/home/img_icn_linked.svg"
                                alt=""
                              />
                              {/* <svg
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_484_14501)">
                                  <path
                                    d="M16.4999 0C25.6131 0 33 7.38787 33 16.5001C33 25.6122 25.6131 33 16.4999 33C7.38674 33 0 25.6121 0 16.5001C0 7.38798 7.38685 0 16.4999 0Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M9.29779 22.671H12.3874V12.3729H9.29779V22.671ZM21.3346 12.0162C19.8351 12.0162 18.4935 12.5637 17.5417 13.7724V12.339H14.4407V22.6711H17.5417V17.0837C17.5417 15.9029 18.6235 14.751 19.9785 14.751C21.3336 14.751 21.6677 15.9029 21.6677 17.0549V22.67H24.7574V16.8249C24.7573 12.7648 22.8351 12.0162 21.3346 12.0162ZM10.8282 11.3437C11.6821 11.3437 12.3751 10.6507 12.3751 9.79681C12.3751 8.94292 11.6821 8.25 10.8282 8.25C9.97428 8.25 9.28125 8.94303 9.28125 9.79692C9.28125 10.6508 9.97428 11.3437 10.8282 11.3437Z"
                                    fill="#000E33"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_484_14501">
                                    <rect width="33" height="33" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg> */}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/@EyeFireCorp"
                              target={'_blank'}
                            >
                              <img
                                src="/assets/img/home/img_icn_youtobe.svg"
                                alt=""
                              />
                              {/* <svg
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16.5"
                                  cy="16.5"
                                  r="16.5"
                                  fill="white"
                                />
                                <path
                                  d="M24.6452 12.8745C24.5484 12.5115 24.3587 12.1805 24.095 11.9146C23.8313 11.6487 23.5028 11.4572 23.1425 11.3592C21.8163 11 16.5 11 16.5 11C16.5 11 11.1837 11 9.85748 11.3573C9.49703 11.4549 9.16842 11.6463 8.90465 11.9123C8.64089 12.1783 8.45126 12.5095 8.3548 12.8726C8 14.2102 8 17 8 17C8 17 8 19.7898 8.3548 21.1255C8.55022 21.8631 9.12701 22.4439 9.85748 22.6408C11.1837 23 16.5 23 16.5 23C16.5 23 21.8163 23 23.1425 22.6408C23.8749 22.4439 24.4498 21.8631 24.6452 21.1255C25 19.7898 25 17 25 17C25 17 25 14.2102 24.6452 12.8745ZM14.8114 19.5605V14.4395L19.2132 16.9809L14.8114 19.5605Z"
                                  fill="#000E33"
                                />
                              </svg> */}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="l-col-auto footer-col-menu">
                      <div className="item-sp" style={{ marginTop: '25px' }}>
                        <h3 className="l-footer__main__title">
                          {trans.contact_now}
                        </h3>
                        <div className="l-footer__main__content__keep-item">
                          <ul style={{ display: ' block' }}>
                            <li>
                              <div className="update_contact_block">
                                <a
                                  className="update_contact_a"
                                  href="tel:+84 24 3910 2030"
                                >
                                  <img
                                    src="/assets/images/home/phone_u.svg"
                                    alt=""
                                  />
                                  <span className="">+84 24 3910 2030</span>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="update_contact_block">
                                <a
                                  className="update_contact_a"
                                  href="mailto:info@eyefire.vn"
                                >
                                  <img
                                    src="/assets/images/home/mail_u.svg"
                                    alt=""
                                  />
                                  <span className="">info@eyefire.vn</span>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="l-footer__main__content-rules item-sp">
                      <a href="#" className="l-footer__main__link">
                        {trans.term}
                      </a>
                      <a href="#" className="l-footer__main__link">
                        {trans.policy}
                      </a>
                    </div>
                  </div>
                  <div
                    className="l-row footer-pc rowTouchRule"
                    style={{ marginTop: '-3vw' }}
                  >
                    <div
                      className="l-col-auto footer-col-menu l-footer__main__office"
                      style={{ width: '375.23px' }}
                    ></div>
                    <div className="l-col-auto footer-col-menu l-footer__main__solution">
                      <div className="l-footer__main__content__keep item-pc">
                        <h3 className="l-footer__main__title">
                          {trans.layout.keep}
                        </h3>
                        <div className="l-footer__main__content__keep-item">
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/EyeFireCorp"
                                target={'_blank'}
                              >
                                <svg
                                  width="40"
                                  height="40"
                                  viewBox="0 0 42 42"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.8867 41.0195C9.84067 41.0195 0.886719 32.0656 0.886719 21.0195C0.886719 9.97347 9.84067 1.01953 20.8867 1.01953C31.9328 1.01953 40.8867 9.97347 40.8867 21.0195C40.8867 32.0656 31.9328 41.0195 20.8867 41.0195Z"
                                    stroke="white"
                                    strokeWidth="0.4321"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M18.1859 31.7397H22.4993V20.9261H25.5126L25.8312 17.3101H22.4993C22.4993 17.3101 22.4993 15.9584 22.4993 15.2524C22.4993 14.4001 22.6715 14.0643 23.498 14.0643C24.1609 14.0643 25.8312 14.0643 25.8312 14.0643V10.3105C25.8312 10.3105 23.3688 10.3105 22.8436 10.3105C19.6323 10.3105 18.1859 11.7225 18.1859 14.4345C18.1859 16.7935 18.1859 17.3187 18.1859 17.3187H15.9388V20.9864H18.1859V31.7397Z"
                                    fill="white"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.linkedin.com/company/tcom-corporation"
                                target={'_blank'}
                              >
                                <svg
                                  width="40"
                                  height="40"
                                  viewBox="0 0 42 42"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.8867 41.0195C9.84067 41.0195 0.886719 32.0656 0.886719 21.0195C0.886719 9.97347 9.84067 1.01953 20.8867 1.01953C31.9328 1.01953 40.8867 9.97347 40.8867 21.0195C40.8867 32.0656 31.9328 41.0195 20.8867 41.0195Z"
                                    stroke="white"
                                    strokeWidth="0.4321"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M16.0128 17.0479H12.3451V28.9032H16.0128V17.0479Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M14.1632 15.4977C15.3599 15.4977 16.3328 14.5162 16.3328 13.3109C16.3328 12.1055 15.3599 11.124 14.1632 11.124C12.9665 11.124 11.9936 12.1055 11.9936 13.3109C11.9936 14.5162 12.9665 15.4977 14.1632 15.4977Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M21.8886 22.6885C21.8886 21.0183 22.6548 20.0282 24.1271 20.0282C25.4788 20.0282 26.1245 20.9838 26.1245 22.6885C26.1245 24.3932 26.1245 28.9133 26.1245 28.9133H29.7749C29.7749 28.9133 29.7749 24.5826 29.7749 21.4057C29.7749 18.2288 27.9756 16.6963 25.4616 16.6963C22.9476 16.6963 21.8886 18.6593 21.8886 18.6593V17.0579H18.3673V28.9133H21.8886C21.8886 28.9133 21.8886 24.5138 21.8886 22.6885Z"
                                    fill="white"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/@EyeFireCorp"
                                target={'_blank'}
                              >
                                <svg
                                  width="40"
                                  height="40"
                                  viewBox="0 0 42 42"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.8867 41.0195C9.84067 41.0195 0.886719 32.0656 0.886719 21.0195C0.886719 9.97347 9.84067 1.01953 20.8867 1.01953C31.9328 1.01953 40.8867 9.97347 40.8867 21.0195C40.8867 32.0656 31.9328 41.0195 20.8867 41.0195Z"
                                    stroke="white"
                                    strokeWidth="0.4321"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M31.6378 15.1813C31.3884 14.1368 30.6151 13.3333 29.6423 13.0387C27.8961 12.5566 20.8867 12.5566 20.8867 12.5566C20.8867 12.5566 13.8773 12.5566 12.1062 13.0655C11.1334 13.3333 10.3851 14.1636 10.1107 15.2081C9.63672 17.0828 9.63672 21.0198 9.63672 21.0198C9.63672 21.0198 9.63672 24.93 10.1107 26.8316C10.3601 27.8761 11.1334 28.6795 12.1062 28.9741C13.8524 29.483 20.8867 29.483 20.8867 29.483C20.8867 29.483 27.8961 29.483 29.6672 28.9741C30.64 28.7063 31.3884 27.8761 31.6628 26.8316C32.1367 24.9568 32.1367 21.0198 32.1367 21.0198C32.1367 21.0198 32.1118 17.0828 31.6378 15.1813ZM18.6417 24.6086V17.3774L24.4787 20.993L18.6417 24.6086Z"
                                    fill="white"
                                  />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="l-col-auto footer-col-menu">
                      <div className="l-footer__main__content__keep item-pc">
                        <h3 className="l-footer__main__title">
                          {trans.contact_now}
                        </h3>
                        <div className="l-footer__main__content__keep-item">
                          <ul style={{ display: ' block' }}>
                            <li>
                              <div className="update_contact_block">
                                <a
                                  className="update_contact_a"
                                  href="tel:+84 24 3910 2030"
                                >
                                  <img
                                    src="/assets/images/home/phone_u.svg"
                                    alt=""
                                  />
                                  <span className="">+84 24 3910 2030</span>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="update_contact_block">
                                <a
                                  className="update_contact_a"
                                  href="mailto:info@eyefire.vn"
                                >
                                  <img
                                    src="/assets/images/home/mail_u.svg"
                                    alt=""
                                  />
                                  <span className="">info@eyefire.vn</span>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="l-col-auto footer-col-menu">
                      <div className="l-footer__main__content">
                        <div className="l-footer__main__content-rules item-pc">
                          <a href="#" className="l-footer__main__link">
                            {trans.term}
                          </a>
                          <a href="#" className="l-footer__main__link">
                            {trans.policy}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="l-footer__copyright">
                <div className="l-container-fluid">
                  <div className="l-footer__copyright__logo">
                    <img
                      src="/assets/img/common/icn_logo-copyright.svg"
                      alt=""
                    />
                  </div>
                  <p>{trans.layout.copyright}</p>
                  <div className="l-footer__copyright__logo l-footer__copyright__logo--right">
                    <a href='http://online.gov.vn/Home/WebDetails/81133' target='_blank'>
                      <img src="/assets/img/common/img_copyright.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>

      {/* end footer */}
    </>
  );
}
export default React.memo(Layout);
