export default {
  home: {
    integrated_title1: 'On Premise',
    integrated_title2: 'Cloud Service',
    integrated_title3: 'Integrated',
    integrated_content1:
      'On Premise: deployed on the server of customers or partners',
    integrated_content2:
      'Cloud Service: Use EYEFIRE’s cloud server, quick deployment',
    integrated_content3:
      'Integration: MobileSDK, ServerSDK, APIs fullfil almost integration needs.',
    title: 'EYEFIRE',
    description:
      'Leading in products and solutions based on facial </br>recognition, object recognition, and behavior recognition in videos and images',
    hotTech: 'Outstanding Technology',
    solutions: 'Solutions',
    solutions_des:
      'Comprehensive solutions, solving real problems.</br> Can be customized to meet all needs',
    product: 'Products',
    product_sub:
      'Products with built-in EyeFire technology have many remarkable features',
    facesdk:
      "<p>Integrate facial recognition technology into existing applications,<br className='item-pc' /> and systems easily through EYEFIRE SDK</p>",
    news: 'News',
    new_des:
      'News about products and technology of EYEFIRE.Events, collaborations, announcements <br/> and more',
    field: 'Fields',
    field_sub: "EYEFIRE's technology can be applied in many fields",
    customer: 'Customers and partners',
    customer_des:
      'EYEFIRE is proud to be a partner of leading businesses and organizations',
    start: 'Integration and Deployment',
    start_des:
      'Multi-platform recognition technology, can be customized to your needs, flexibility in  deployment.',
    contact: 'Contact us for a consultation',

    prize_title: '<h1>the media talk about eyefire</h1>',
  },
  tecnology: {
    hotTech: 'Outstanding Technology',
    description:
      'Leading in products and solutions based on facial recognition, object recognition, and behavior recognition in videos and images',
  },
  layout: {
    vn: 'VN',
    el: 'EN',
    jp: 'JP',
    home: 'Home',
    technology: 'Technology',
    product: 'Product',
    solution: 'Solution',
    sdk: 'SDK',
    job: 'Tuyển dụng',
    news: 'News',
    contact: 'Contact Us',
    slogan:
      'Leading in products and solutions based on facial recognition, object recognition, and behavior recognition in videos and images',
    office: 'OFFICE',
    menu: 'Menu',
    adrHn: 'Head Office',
    adrHcm: 'Ho Chi Minh Office',
    adrJp: 'Tokyo Office',
    address1:
      '3rd floor, Fafim A Building, 19 Nguyen Trai Street Thanh Xuan District, Ha Noi City, Viet Nam',
    address2:
      '5F, VTD Building 17 Ho Van Hue Street, Ward 9, Phu Nhuan District, Ho Chi Minh City, Viet Nam',
    address3:
      '504 Tachikawa NX Building 5F, 3-8-5 Shibasakicho, Tachikawa, Tokyo, Japan 190-0023',
    rule1: 'Điều khoản dịch vụ',
    rule2: 'Chính sách bảo mật',
    keep: 'CONNECT WITH US',
    copyright: 'Copyright © 2023EYEFIRE Corporation. All rights reserved.',
    contact_footer: 'Contact Us',
    content_contact_footer1:
      'Send information for advice, quotation, or business cooperation contact.',
    content_contact_footer2:
      'Please email us at any time. We are ready to listen and support you!',
    button_contact: 'CONTACT US',
    button_detail: 'Detail',
    btn_send: 'Send',
    title_contact: 'Contact Information',
    content_contact1:
      'Just shoot over your details if you need some advice, a price quote, or to set up some sort of business partnership. You can contact us whenever you like by sending an email.',
    content_contact2:
      "We're here to hear you out and give you our full backing.",
  },
  sdk: {
    title_ft1: 'Face Recognition',
    title_ft2: 'Liveness',
    title_ft3: 'Mask Detection',
    content_ft1: 'Face Recognition',
    content_ft2: 'Liveness',
    content_ft3: 'Mask Detection',
    slogan_sdk: 'FACE RECOGNITION SOFTWARE DEVELOPMENT KIT',
    title_sdk: 'EYEFIRE FACESDK',
    content_sdk1:
      'Introduce EYEFIRE’s advanced facial recognition technology into your business’services',
    button_try: 'Thử nghiệm ngay',
    title_big_box: 'EYEFIRE FaceSDK',
    content_big_box:
      'Multi-platform, easy to be integrated into mobile applications and backend systems running on iOS, Android, Server',
    sdk1: 'iOS SDK',
    sdk2: 'Android SDK',
    sdk3: 'Server SDK',
    feature_title:
      'Introducing recognition technology into your business’s services is easier than ever',
    face_title: 'Face recognition technology',
    face_content: 'Developed and owned by EYEFIRE, tested and deployed.',
    feature_sdk_title: 'Key features of SDK',
    feature_sdk_content: 'Developed and owned by EYEFIRE, tested and deployed.',
    title_box1: 'Accuracy',
    title_box2: 'Fast speed',
    title_box3: '1 sample image',
    title_box4: 'Safety and security',
    title_box5: 'Flexibility to expand',
    content_box1:
      'Up to <span className="red-text">99.9%</span> under normal operating conditions, can recognize users even in glasses and hats',
    content_box2:
      'Recognition speed less than <span className="red-text">1s</span>. Technically, the average recognition speed is from <span className="red-text">200-300ms</span> with a data set of 20-30k users',
    content_box3:
      'With only <span className="red-text">1 sample image</span> to use as training data, the training engine can process it to ensure the accuracy and speed requirements of later recognition',
    content_box4: 'Accurate identification, prevent face spoofing',
    content_box5:
      'EYEFIRE’s facial recognition technology is multi-platform, easy to be synced and customized to your business needs',
    tec_box_1: 'Fast integration',
    tec_box_2: 'High-Performance',
    tec_box_3: 'Latest Technology',
    tec_box_4: 'Compact',
    tec_content_box1:
      'Functions and structure are designed visually, clear user’s manual for a quicker grasp of  how things work',
    tec_content_box2:
      'EYEFIRE’s FaceSDK is a high- performance SDK that ensures recognition speed and ensures accuracy on most devices',
    tec_content_box3:
      'The SDK is constantly upgraded by EYEFIRE’s development team with the latest technologies',
    tec_content_box4:
      'SDK capacity and A.I model are small,  easy to store, suitable for mobile devices',
  },
  product: {
    product: 'Products',
    product_des:
      'Products with built-in EyeFire technology have many remarkable features',
  },
  news: {
    news: 'News',
    new_des:
      'Pioneering in products and solutions based on facial recognition technology, object recognition in videos, images',
    new_hot: 'Featured news',
    new_hostlist:
      "Let's take a look at the remarkable news <br/> in the past time from Eyefire",
    list_hot: 'The latest news',
    search: 'Search',
    relate: 'Related news',
    back: 'back',
  },
  text_button: {
    read_more: 'READ MORE',
    detail: 'See details',
    more: 'See more',
    back: 'Back',
  },
  contact: {
    form_mail: 'Work mail',
    form_name: 'Fullname',
    form_phone: 'Phone',
    form_subject: 'Subject',
    form_purpose: 'How can we help',
    form_quotation: 'Quotation',
    form_request: 'Request a consultation',
    form_cooperation: 'Cooperation',
    form_recruitment: 'Recruitment',
    form_orther: 'Other',
    form_message: 'Your message',
    form_file: 'File attachment',
  },
  solution: {
    title_solution: 'Solutions',
    content_solution:
      '<p>Comprehensive solutions, solving real problems. Can be customized to meet all needs</p>',
  },
  error: {
    title: 'Not found',
    button: 'Back',
  },
  seo: {
    home: {
      title: 'Trang chủ',
      description:
        'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
    },
    technology: {
      title: 'Công nghệ',
      description:
        'Công nghệ nhận diện khuôn mặt, nhận diện phương tiện giao thông, nhận diện hành vi bất thường, phát hiện sản phẩm lỗi, phát hiện chuyển động, phát hiện xâm nhập, hàng rào ảo, AIoT camera',
    },
    solution: {
      title: 'Giải pháp',
      description:
        'Giải pháp toàn diện, có thể tuỳ biến đáp ứng mọi nhu cầu của khách hàng bao gồm Giải pháp trường học thông minh, Giải pháp toà nhà thông minh, Giải pháp AIoT camera biến camera thường thành AI camera, Giải pháp tự động hoá dây chuyền sản xuất',
    },
    product: {
      title: 'Sản phẩm',
      description:
        'Thiết bị nhận diện chuyên dụng được EYEFIRE phát triển, tích hợp chức năng nhận diện khuôn mặt, nhận diện thẻ quẹt, kết nối với hệ thống mở cửa tự động, hệ thống cảnh báo an ninh giám sát',
    },
    news: {
      title: 'Tin tức',
      description:
        'Những tin tức, sự kiện nổi bật về hoạt động hợp tác kinh doanh, cũng như các hoạt động nội bộ của EYEFIRE',
    },
    sdk: {
      title: 'SDK',
      description:
        'Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng, các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK đa nền tảng bao gồm iOS SDK, Android SDK, Server SDK',
    },
    contact: {
      title: 'Liên hệ',
      description:
        'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh. Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    },
  },
  banner: {
    solution: 'Solution',
    slide3: {
      title: 'Workplace Safety',
      img_prize: 'img_prize_2024_en.png',
      desc:
        'Comprehensive solution for managing and monitoring safety in factories through CCTV Cameras integrated with AI features, helping to detect and warn early of potential safety risks, thereby preventing incidents. problems may occur.',
      img_desktop: 'factory.svg',
      img_mb: 'Safety.png',
    },
    slide1: {
      title: 'Smart school',
      img_prize: 'img_prize_2023_en.png',
      desc:
        'Optimize all daily activities of the school, and at the same time create an effective communication channel between the school and parents.',
      img_desktop: 'img_banner_pc.png',
      img_mb: 'bannerMobile2.png',
    },
    slide2: {
      title: 'AIOT Camera',
      desc:
        'The solution to turn regular cameras into AI cameras, centralized camera management and storage, cloud platform, allowing viewing and analyzing images through artificial intelligence technology.',
      img_desktop: 'solution.svg',
      img_mb: 'bannerMobile3.png',
    },
    slide4: {
      title: 'Unmistakable face recognition',
      img_prize: 'img_prize_2024_en.png',
      desc:
        'Multi-platform, accurate, fast-speed facial recognition solution, applied to timekeeping, attendance, and smart access control problems in most fields.',
      img_desktop: 'Face.png',
      img_mb: 'Face.png',
    },
  },
  term: 'Terms of Service',
  policy: 'Privacy Policy',
  office: 'Office',
  contact_now: 'CONTACT NOW',
};
